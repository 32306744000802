var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BT-Item',{attrs:{"navigation":"client-invoice","canRefresh":true,"canDelete":true,"width":"650"},on:{"fetched":_vm.prepItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"mx-auto px-sm-1",attrs:{"elevation-1":"","max-width":"650"}},[_c('v-img',{staticClass:"mx-auto primary--text",attrs:{"src":_vm.companyLogoURL(item.buyerID),"height":"100","width":"100"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","size":"100"}},[_vm._v("mdi-account")])]},proxy:true}],null,true)}),_c('v-card-title',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text--secondary ml-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text--secondary"})],1),_vm._v(" "+_vm._s(item.buyer.companyName)+" ")],1),_c('v-card-text',[_c('v-row',[_c('v-col'),_c('v-spacer'),_c('v-col',[_vm._v("INV#"+_vm._s(item.invoiceNumber))])],1),_c('v-row',[_c('v-col',[_vm._v("Status")]),_c('v-spacer'),_c('v-col',[_vm._v(_vm._s(item.isPaid ? 'Paid' : 'Unpaid'))])],1),(item.isPaid)?_c('v-row',[_c('v-col',[_vm._v("Paid On")]),_c('v-spacer'),_c('v-col',[_vm._v(_vm._s(_vm._f("toShortDate")(item.paidOn)))])],1):_vm._e(),_c('v-row',[_c('v-col',[_vm._v("Due On")]),_c('v-spacer'),_c('v-col',[_vm._v(_vm._s(_vm._f("toShortDate")(item.dueOn)))])],1),_c('v-row',[_c('v-col',[_vm._v("Issued On")]),_c('v-spacer'),_c('v-col',[_vm._v(_vm._s(_vm._f("toShortDate")(item.issuedOn)))])],1)],1),_c('BT-Table-Basic',{attrs:{"label":"Line Items","items":item.lineItems,"canCreate":false,"canDelete":false,"canEdit":false,"headers":[{ text: 'QTY', value: 'quantity' },
                                    { text: 'Description', value: 'description' },
                                    { text: 'Unit Price', value: 'unitPrice' },
                                    { text: 'Line Total', value: 'totalPrice' }],"displayFilters":['quantity', 'unitPrice', 'totalPrice']},scopedSlots:_vm._u([{key:"quantity",fn:function(ref){
                                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("toDisplayNumber")(item.quantity)))])]}},{key:"unitPrice",fn:function(ref){
                                    var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm._f("toCurrency")(item.unitPrice)))])]}},{key:"totalPrice",fn:function(ref){
                                    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("toCurrency")(item.unitPrice * item.quantity)))])]}}],null,true)},[_vm._v("> ")]),_c('h4',{staticClass:"text-right ma-5"},[_vm._v("Subtotal: "+_vm._s(_vm._f("toCurrency")(item.subTotal)))]),_c('h4',{staticClass:"text-right ma-5"},[_vm._v("GST: "+_vm._s(_vm._f("toCurrency")(item.taxTotal)))]),_c('h3',{staticClass:"text-right ma-5"},[_vm._v("Total: "+_vm._s(_vm._f("toCurrency")(item.amountTotal)))]),(!item.isPaid && item.amountPaid > 0)?_c('h2',{staticClass:"text-right error--text ma-5"},[_vm._v("Amount Paid: "+_vm._s(_vm._f("toCurrency")(item.amountPaid)))]):_vm._e(),(!item.isPaid)?_c('h2',{staticClass:"text-right warning--text ma-5"},[_vm._v(" Amount Due: "+_vm._s(_vm._f("toCurrency")(item.amountTotal - item.amountPaid))+" ")]):_vm._e(),(item.amountPaid != item.amountTotal)?_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('BT-Single-Action-Dialog',{attrs:{"label":"Amount Paid","text":"Paid","icon":"mdi-credit-card-outline","width":"300","okText":"Paid","item":_vm.amountPaid,"isDone":item.amountPaid == item.amountTotal},on:{"ok":function($event){return _vm.paid(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Amount","type":"number","prefix":"$"},model:{value:(item.v),callback:function ($$v) {_vm.$set(item, "v", _vm._n($$v))},expression:"item.v"}})]}}],null,true)}),(item.amountPaid == item.amountTotal)?_c('v-btn',{staticClass:"mx-1",on:{"click":function($event){item.amountPaid = item.amountTotal}}},[_vm._v(" Fully Paid ")]):_vm._e()],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }