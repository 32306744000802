<template>
    <BT-Item
        navigation="client-invoice"        
        :canRefresh="true"
        :canDelete="true"
        width="650"
        @fetched="prepItem">
        <template slot-scope="{ item }">                        
            <v-card                 
                elevation-1
                max-width="650"
                class="mx-auto px-sm-1">                                
                
                        <v-img :src="companyLogoURL(item.buyerID)" height="100" width="100"
                            class="mx-auto primary--text">
                            <template v-slot:placeholder><v-icon color="primary" size="100">mdi-account</v-icon></template>
                        </v-img>

                        <v-card-title>                            
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn 
                                        icon 
                                        class="text--secondary ml-3" 
                                        v-bind="attrs" 
                                        v-on="on">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                </template>

                                <v-list class="text--secondary">                                                                
                                     <!-- <v-list-item class="text--secondary">                                        
                                        <v-list-item-subtitle>Courier Order #</v-list-item-subtitle>
                                        <label>{{ item.courierOrderNumber }}</label>                                        
                                    </v-list-item>     -->
                                </v-list>
                            </v-menu>    
                            {{ item.buyer.companyName }}                         
                        </v-card-title>
                        
                        <v-card-text>
                            <v-row>
                                <v-col></v-col>
                                <v-spacer />
                                <v-col>INV#{{ item.invoiceNumber }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col>Status</v-col>
                                <v-spacer />
                                <v-col>{{ item.isPaid ? 'Paid' : 'Unpaid' }}</v-col>
                            </v-row>
                            <v-row v-if="item.isPaid">
                                <v-col>Paid On</v-col>
                                <v-spacer />
                                <v-col>{{ item.paidOn | toShortDate }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col>Due On</v-col>
                                <v-spacer />
                                <v-col>{{ item.dueOn | toShortDate }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col>Issued On</v-col>
                                <v-spacer />
                                <v-col>{{ item.issuedOn | toShortDate }}</v-col>
                            </v-row>
                        </v-card-text>
                        <BT-Table-Basic
                            label="Line Items"
                            :items="item.lineItems"
                            :canCreate="false"
                            :canDelete="false"
                            :canEdit="false"
                            :headers="[{ text: 'QTY', value: 'quantity' },
                                        { text: 'Description', value: 'description' },
                                        { text: 'Unit Price', value: 'unitPrice' },
                                        { text: 'Line Total', value: 'totalPrice' }]"
                            :displayFilters="['quantity', 'unitPrice', 'totalPrice']">
                            <template slot="quantity" slot-scope="{ item }">
                                <div>{{ item.quantity | toDisplayNumber }}</div>
                            </template>
                            <template slot="unitPrice" slot-scope="{ item }">
                                <label>{{ item.unitPrice | toCurrency }}</label>
                            </template>
                            <template slot="totalPrice" slot-scope="{ item }">
                                <div>{{ item.unitPrice * item.quantity | toCurrency }}</div>
                            </template>>   
                        </BT-Table-Basic>

                        <h4 class="text-right ma-5">Subtotal: {{ item.subTotal | toCurrency }}</h4>
                        <h4 class="text-right ma-5">GST: {{ item.taxTotal | toCurrency }}</h4>

                        <h3 class="text-right ma-5">Total: {{ item.amountTotal | toCurrency }}</h3>

                        <h2 v-if="!item.isPaid && item.amountPaid > 0" class="text-right error--text ma-5">Amount Paid: {{ item.amountPaid | toCurrency }}</h2>
                        <h2 v-if="!item.isPaid" class="text-right warning--text ma-5">                            
                            Amount Due: {{ item.amountTotal - item.amountPaid | toCurrency }}
                        </h2>

                        <v-card-text v-if="item.amountPaid != item.amountTotal"  >
                            <v-row>
                                <v-spacer />
                                <BT-Single-Action-Dialog                                         
                                    label="Amount Paid"                         
                                    text="Paid"
                                    icon="mdi-credit-card-outline"
                                    width="300"
                                    okText="Paid"
                                    :item="amountPaid"
                                    @ok="paid(item)"                                
                                    :isDone="item.amountPaid == item.amountTotal">
                                    <template slot-scope="{ item }">                                    
                                        <v-text-field 
                                            label="Amount"
                                            type="number"
                                            v-model.number="item.v"
                                            prefix="$" />
                                    </template>
                                </BT-Single-Action-Dialog>
                                <v-btn v-if="item.amountPaid == item.amountTotal" class="mx-1" @click="item.amountPaid = item.amountTotal">
                                    Fully Paid
                                </v-btn>
                            </v-row>                            
                            <!-- <BTStripeButton v-if="!item.isPaid" @click="payWithStripe(item.id)" text="Pay with" /> -->
                            <!-- <v-btn v-if="!item.isPaid" class="ml-auto primary" @click="payWithStripe(item.id)">Paid</v-btn> -->
                        </v-card-text>
                        
            </v-card>
        </template>
    </BT-Item>
</template>

<script>
//import { loadStripe } from '@stripe/stripe-js';

export default {
    name: 'Client-Invoice',
    components: {        
        BTTableBasic: () => import('~components/BT-Table-Basic.vue'),
        BTSingleActionDialog: () => import('~components/BT-Single-Action-Dialog.vue')
    },  
    data: function() {
        return {
            amountPaid: { v: 0 }
        }
    },
    methods: {
        paid(item) {
            item.amountPaid = this.amountPaid.v;
        },
        prepItem(item) {
            item.lineItems = item.lineItems.orderBy('sortNumber');
        }
    }
}
</script>